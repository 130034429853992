<template>
  <section class="almacen-equipos px-3 container-fluid overflow-auto custom-scroll mt-2">
        <titulo-divisor titulo="Lista de equipos modelos">
            <div class="row">
                <div class="col auto">
                    <input-search v-model="buscarListado" placeholder="Buscar equipo" @keypress.native.enter="search()" size="small" />
                </div>
                <div class="col-auto my-auto px-1">
                    <button class="btn btn-general f-12 px-4"
                        :class="permitAction('almacen.equipos.crear') ? 'cr-pointer' : 'cr-not-allowed'" 
                        @click="functionPermitAction('almacen.equipos.crear', crearEquipo)"
                    >Crear equipo</button>
                </div>
            </div>
        </titulo-divisor>
        <div class="row listado-equipos custom-scroll">
            <div v-for="(equipo, idx) in equipos.data" :key="idx" @click="verEquipo(equipo.id)" class="col-12 col-lg-6 col-xl-5 mb-3">
                <card-model-machine 
                    :img="equipo.imagen"
                    :name="equipo.nombre"
                    :price="equipo.precio"
                    :cantPhases="equipo.etapas"
                    :cantMaterials="equipo.materiales"
                    :cantHours="equipo.horas_hombre"
                />
            </div>
        </div>
        <div class="d-middle-center mt-4">
            <Pagination :pagination="equipos.pagination" layout="prev, pager, next, jumper" @paginate="listarEquipos"/>
        </div>
        <!-- partials  -->
        <modal-agregar-equipo ref="modalAgregarEquipo" />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'equiposModelos',
    components:{
      modalAgregarEquipo: () => import('./partials/modalAgregarEquipo'),
      cardModelMachine: () => import('../components/cardModelMachine'),
    },
    data(){
        return{
            buscarListado: null,
            machineModelList:[
                {
                    id:1,
                    name:'Reactor de lecho fluidizado',
                    price:'140.000,00',
                    cantPhases:5,
                    cantMaterials:84,
                    cantHours:540,
                },
                {
                    id:2,
                    name:'Spray Dryers',
                    price:'98.000,00',
                    cantPhases:4,
                    cantMaterials:34,
                    cantHours:641,
                },
            ],
            filtered: [],
        }
    },
    async created(){
        this.getBreadcumbs([ 'almacen.main', 'almacen.equipos' ]);
        await this.listarEquipos()
        await this.Action_get_selects_categorias()
        // this.filtered = this.equipos.data;
    },
    computed: {
        ...mapGetters({
			user: 'auth/user',
            equipos: 'almacen/equiposModelos/equipos',
        })
    },
    methods:{
        ...mapActions({
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            Action_get_equipos_modelos: 'almacen/equiposModelos/Action_get_equipos_modelos',
            Action_get_selects_categorias: 'selects/selects/Action_get_selects_categorias',
        }),
        async listarEquipos(page = 1){
            await this.Action_get_equipos_modelos({page, buscar:this.buscarListado})
        },
        crearEquipo(){
            this.$refs.modalAgregarEquipo.toggle()
        },
        verEquipo(id){
            this.$router.push({name: 'almacen.equipos.ver', params: {id}})
        },
        async search(){
            await this.listarEquipos();
            // this.filtered = this.equipos.data.filter(el => el.nombre.toLowerCase().includes(this.buscarListado.toLowerCase()));
        }
    }

}
</script>

<style lang="scss" scoped>
    .almacen-equipos{
        height: calc(100vh - 240px);
    }
    .listado-equipos{
        height: calc(100vh - 360px);
        overflow-y: scroll;
    }
</style>